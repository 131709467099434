// + de paramètres possible sur https://swiperjs.com/demos et sur https://swiperjs.com/swiper-api
var swiper = new Swiper(".diaporama", {
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
        nextEl: ".diaporama-buttonNext",
        prevEl: ".diaporama-buttonPrev",
    },
});

var swiper = new Swiper(".widgetCatalogue-swiper", {
    spaceBetween: 15,
    slidesPerView: 1,
    navigation: {
        nextEl: ".widgetCatalogue-buttonNext",
        prevEl: ".widgetCatalogue-buttonPrev",
    },
    breakpoints: {
        576: {
            slidesPerView: 2,
        },
        992: {
            slidesPerView: 3,
        }
    }
});

var swiper = new Swiper(".widgetActualite-swiper", {
    spaceBetween: 100,
    slidesPerView: 1,
    pagination: {
        el: ".widgetActualite-pagination",
        bulletClass: 'swiper-pagination-bullet widgetActualite-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active widgetActualite-bullet--active'
    },
    navigation: {
        nextEl: ".widgetActualite-button-next",
        prevEl: ".widgetActualite-button-prev",
    },
    breakpoints: {

        767: {
            slidesPerView: 2,
        }
    }
});

var swiper = new Swiper(".ficheProduit-swiper", {
    spaceBetween: 15,
    slidesPerView: 3,
    navigation: {
        nextEl: ".ficheProduit-buttonNext",
        prevEl: ".ficheProduit-buttonPrev",
    },
    breakpoints: {
        370: {
            slidesPerView: 4,
        },
        768: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        }
    }
});
